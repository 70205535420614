// LeaderboardPage.jsx

import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { merge } from 'lodash';
import zoomPlugin from 'chartjs-plugin-zoom';
import { format } from 'date-fns';
import { AuthContext } from '../contexts/AuthContext';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, zoomPlugin);

const LoadingSpinner = ({ theme }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        theme === 'dark'
          ? 'bg-black bg-opacity-75'
          : theme === 'sunset'
          ? 'bg-gray-900 bg-opacity-75'
          : 'bg-gray-100 bg-opacity-75'
      }`}
    >
      <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
    </div>
  );
};

const colorPalette = {
  light: ['#064e3b', '#10b981', '#f97316', '#1d4ed8', '#dc2626'],
  dark: ['#006400', '#00b200', '#ffaa00', '#0056b3', '#990000'],
  sunset: ['#64ffda', '#52d6b5', '#ff7f00', '#0077b6', '#b20000'],
};

const getUserColor = (theme, index) => {
  const colors = colorPalette[theme] || colorPalette.light;
  return colors[index % colors.length];
};

const calculateDateRange = (timeframe) => {
  const now = new Date();
  const minDate = new Date('2024-08-17');
  let startDate;
  let endDate = now.toISOString().split('T')[0];

  if (timeframe === 'last_week') {
    const lastWeek = new Date(now);
    lastWeek.setDate(now.getDate() - 7);
    startDate = lastWeek >= minDate ? lastWeek.toISOString().split('T')[0] : minDate.toISOString().split('T')[0];
  } else if (timeframe === 'last_month') {
    const lastMonth = new Date(now);
    lastMonth.setMonth(now.getMonth() - 1);
    startDate = lastMonth >= minDate ? lastMonth.toISOString().split('T')[0] : minDate.toISOString().split('T')[0];
  } else if (timeframe === 'all_time') {
    startDate = minDate.toISOString().split('T')[0];
  }

  return { startDate, endDate };
};

const removeDuplicateEntries = (data) => {
  const uniqueData = [];
  const seen = new Set();

  for (const entry of data) {
    const key = `${entry.id}-${entry.snapshot_date}`;
    if (!seen.has(key)) {
      seen.add(key);
      uniqueData.push(entry);
    }
  }

  return uniqueData;
};

const calculateRankChange = (currentRank, previousRank) => {
  const change = previousRank - currentRank;
  if (change > 0) {
    return { symbol: '⬆️', change };
  } else if (change < 0) {
    return { symbol: '⬇️', change: Math.abs(change) };
  } else {
    return { symbol: '➡️', change: 0 };
  }
};

const processTrendData = (data, startDate, endDate, theme, isContest) => {
  const users = [...new Set(data.map((d) => d.name))];
  const dates = [];
  let currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dates.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Create a map of user to their start score if it's a contest
  const startScores = {};
  if (isContest) {
    data.forEach((d) => {
      if (d.snapshot_date === startDate) {
        startScores[d.name] = d.score;
      }
    });
  }

  const datasets = users.map((user, index) => {
    const userData = data.filter((d) => d.name === user);
    const userStartScore = isContest ? (startScores[user] || 0) : 0; // Default to 0 if no start score
    return {
      label: user,
      data: dates.map((date) => {
        const record = userData.find((d) => {
          const snapshotDate = new Date(d.snapshot_date).toISOString().split('T')[0];
          return snapshotDate === date;
        });
        return record ? (record.score - userStartScore) : null; // Normalize score
      }),
      fill: false,
      borderColor: getUserColor(theme, index),
      tension: 0.4,
    };
  });

  return {
    labels: dates.map((date) => new Date(date)),
    datasets,
  };
};

const LeaderboardPage = ({ league, theme, favoriteTeams = [] }) => {
  const { user, isAuthenticated } = useContext(AuthContext);
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [trendData, setTrendData] = useState(null);
  const [timeframe, setTimeframe] = useState('all_time');
  const [historyDateRange, setHistoryDateRange] = useState({
    start: '2024-08-17',
    end: new Date().toISOString().split('T')[0],
  });
  const [activeContests, setActiveContests] = useState([]);
  const [upcomingContests, setUpcomingContests] = useState([]);
  const [selectedOption, setSelectedOption] = useState('all_time'); // State to manage selected option
  const [newContest, setNewContest] = useState({
    name: '',
    startDate: '',
    endDate: '',
    firstPlacePrize: '',
    secondPlacePrize: '',
    thirdPlacePrize: '',
    visibility: 'public',
  });
  const [editContest, setEditContest] = useState(null); // For editing contests
  const [image, setImage] = useState(null); // For handling image uploads
  const [dominantColors, setDominantColors] = useState({}); // For storing dominant colors of contest images

  const navigate = useNavigate();

  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      userText: 'text-gray-600',
      highlight: 'text-[#064e3b]',
      hover: 'hover:bg-gray-100 hover:bg-opacity-50',
      correct: 'bg-green-100',
      incorrect: 'bg-red-100',
      correctHover: 'hover:bg-green-200',
      incorrectHover: 'hover:bg-red-200',
      highlightUser: 'bg-green-50',
      overlayColor: 'rgba(255, 255, 255, 0.5)', // Light theme overlay color
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      userText: 'text-gray-400',
      highlight: 'text-white',
      hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
      correct: 'bg-[#004400]',
      incorrect: 'bg-[#440000]',
      correctHover: 'hover:bg-[#006600] hover:bg-opacity-50',
      incorrectHover: 'hover:bg-[#660000] hover:bg-opacity-50',
      highlightUser: 'bg-[#1a1a1a]',
      overlayColor: 'rgba(0, 0, 0, 0.5)', // Dark theme overlay color
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      userText: 'text-[#64ffda]',
      highlight: 'text-[#52d6b5]',
      hover: 'hover:bg-gray-800 hover:bg-opacity-70',
      correct: 'bg-[#005f37]',
      incorrect: 'bg-[#9b0000]',
      correctHover: 'hover:bg-[#007a4f] hover:bg-opacity-50',
      incorrectHover: 'hover:bg-[#b20000] hover:bg-opacity-50',
      highlightUser: 'bg-[#254540]',
      overlayColor: 'rgba(100, 255, 218, 0.5)', // Sunset theme overlay color
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const fetchContests = async () => {
    try {
      const response = await axios.get(`/api/${league}/contests`);
      // console.log('Fetched contests:', response.data);
      const now = new Date();

      // Separate active and upcoming contests
      const active = response.data.filter(
        (contest) => new Date(contest.start_date) <= now && new Date(contest.end_date) >= now
      );
      const upcoming = response.data.filter((contest) => new Date(contest.start_date) > now);

      setActiveContests(active);
      setUpcomingContests(upcoming);

      // console.log('Active contests:', active);
      // console.log('Upcoming contests:', upcoming);
    } catch (error) {
      // console.error('Error fetching contests:', error.message);
    }
  };

  useEffect(() => {
    fetchContests();
  }, [league]);

  useEffect(() => {
    if (
      selectedOption &&
      selectedOption !== 'all_time' &&
      selectedOption !== 'last_week' &&
      selectedOption !== 'last_month' &&
      selectedOption !== 'custom'
    ) {
      // When a contest is selected
      const selectedContest = activeContests.find((contest) => contest.id === parseInt(selectedOption));
      if (selectedContest) {
        fetchLeaderboardAndTrendData(true, selectedContest.start_date, selectedContest.end_date, selectedContest.id);
      }
    } else {
      fetchLeaderboardAndTrendData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, league, user?.id, historyDateRange, activeContests]);

  const fetchLeaderboardAndTrendData = async (isContest = false, startDate, endDate, contestId) => {
    setLoading(true); // Start loading
    try {
      const params = { userId: user?.id };

      if (isContest && contestId) {
        params.contestId = contestId;
        params.startDate = startDate;
        params.endDate = endDate;
      } else if (timeframe !== 'custom') {
        const range = calculateDateRange(timeframe);
        params.startDate = range.startDate;
        params.endDate = range.endDate;
      } else {
        params.startDate = historyDateRange.start;
        params.endDate = historyDateRange.end;
      }

      // console.log('Fetching leaderboard with params:', params); // Logging parameters

      const response = await axios.get(`/api/${league}/leaderboard`, { params });
      const leaderboardData = response.data.leaderboard.map((user) => ({
        ...user,
        points: user.points || 0,
      }));

      setLeaderboard(leaderboardData);

      // console.log('Fetched leaderboard:', leaderboardData); // Logging leaderboard data

      // Set selectedUsers based on whether it's a contest or not
      let topUsers = [];
      if (isContest) {
        topUsers = leaderboardData.slice(0, 3).map((user) => user.id); // Top 3 for contests
      } else {
        topUsers = leaderboardData.slice(0, 3).map((user) => user.id); // Top 3 for standard leaderboards
      }

      setSelectedUsers(topUsers);

      if (!leaderboardData.length) {
        setTrendData(null);
      }
      // Removed direct call to fetchTrendData to prevent redundant fetching
    } catch (error) {
      // console.error('Error fetching leaderboard:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const fetchTrendData = async (userIds, startDate, endDate, isContest) => {
    if (userIds.length === 0) return;

    const params = { userIds, league };

    if (isContest && selectedOption) {
      const selectedContest = activeContests.find((contest) => contest.id === parseInt(selectedOption));
      if (selectedContest) {
        params.contestId = selectedContest.id;
        params.startDate = startDate || selectedContest.start_date;
        params.endDate = endDate || selectedContest.end_date;
      }
    } else if (startDate && endDate) {
      params.startDate = startDate;
      params.endDate = endDate;
    }

    // console.log('Fetching trend data with params:', params); // Logging parameters

    try {
      const response = await axios.get(`/api/${league}/leaderboard-history`, { params });
      const filteredData = removeDuplicateEntries(response.data);
      const data = processTrendData(filteredData, startDate, endDate, theme, isContest); // Pass isContest
      setTrendData(data);

      // console.log('Fetched trend data:', data); // Logging trend data

      if (isContest && data.datasets.length === 0) {
        // Contest has no data, don't render chart or leaderboard
        setTrendData(null);
        setLeaderboard([]);
      }
    } catch (error) {
      // console.error('Error fetching trend data:', error);
      setTrendData(null);
    }
  };

  // **Issue 1 & 2 Fix: Refactored useEffect to handle trend data fetching based on selectedUsers**
  useEffect(() => {
    const isContest =
      selectedOption !== 'all_time' &&
      selectedOption !== 'last_week' &&
      selectedOption !== 'last_month' &&
      selectedOption !== 'custom';
    let startDate, endDate;

    if (isContest) {
      const selectedContest = activeContests.find((contest) => contest.id === parseInt(selectedOption));
      if (selectedContest) {
        startDate = selectedContest.start_date;
        endDate = selectedContest.end_date;
      }
    } else if (timeframe !== 'custom') {
      const range = calculateDateRange(timeframe);
      startDate = range.startDate;
      endDate = range.endDate;
    } else {
      startDate = historyDateRange.start;
      endDate = historyDateRange.end;
    }

    if (selectedUsers.length > 0) {
      fetchTrendData(selectedUsers, startDate, endDate, isContest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, timeframe, historyDateRange, selectedOption, activeContests]);

  const handleUserSelection = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleSelectionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    // console.log('Selected option:', selectedValue); // Logging the selected option

    if (selectedValue === 'custom') {
      setTimeframe('custom');
      // setSelectedOption('custom'); // Already set above
    } else if (selectedValue === 'all_time' || selectedValue === 'last_week' || selectedValue === 'last_month') {
      setTimeframe(selectedValue);
      // setSelectedOption(selectedValue); // Already set above
    } else {
      // It's a contest ID
      setTimeframe('contest'); // Optional: Set a special timeframe for contests
    }
  };

  const handleHistoryDateRangeChange = (field, value) => {
    if (value.length === 10 && new Date(value) >= new Date('2024-08-17')) {
      setHistoryDateRange((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editContest) {
      setEditContest((prev) => ({ ...prev, [name]: value || '' })); // Ensure the value is not undefined
    } else {
      setNewContest((prev) => ({ ...prev, [name]: value || '' })); // Ensure the value is not undefined
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Update image state with selected file
  };

  const handleCreateContest = async () => {
    try {
      const formData = new FormData();
      formData.append('name', newContest.name);
      formData.append('startDate', newContest.startDate);
      formData.append('endDate', newContest.endDate);
      formData.append('firstPlacePrize', newContest.firstPlacePrize);
      formData.append('secondPlacePrize', newContest.secondPlacePrize);
      formData.append('thirdPlacePrize', newContest.thirdPlacePrize);
      formData.append('visibility', newContest.visibility);
      if (image) {
        formData.append('image', image);
      }

      // Get the league from localStorage
      const isNFL = localStorage.getItem('isNFL') === 'true';
      const leagueParam = isNFL ? 'nfl' : 'cl';
      formData.append('league', leagueParam);

      const token = localStorage.getItem('authToken');

      await axios.post(
        `/api/admin/contests`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      setNewContest({
        name: '',
        startDate: '',
        endDate: '',
        firstPlacePrize: '',
        secondPlacePrize: '',
        thirdPlacePrize: '',
        visibility: 'public',
      });
      setImage(null); // Clear the image state

      fetchContests(); // Reload contests after creation
    } catch (error) {
      // console.error('Error creating contest:', error.message);
    }
  };

  const handleEditContest = (contest) => {
    // Populate the editContest state with the selected contest's details
    setEditContest({
      id: contest.id,
      name: contest.name || '',
      startDate: contest.start_date ? contest.start_date.split('T')[0] : '',
      endDate: contest.end_date ? contest.end_date.split('T')[0] : '',
      firstPlacePrize: contest.first_place_prize || '',
      secondPlacePrize: contest.second_place_prize || '',
      thirdPlacePrize: contest.third_place_prize || '',
      visibility: contest.visibility || 'public',
    });
    setImage(null); // Reset the image state when editing
  };

  const handleUpdateContest = async () => {
    try {
      const formData = new FormData();
      formData.append('name', editContest.name);
      formData.append('startDate', editContest.startDate);
      formData.append('endDate', editContest.endDate);
      formData.append('firstPlacePrize', editContest.firstPlacePrize);
      formData.append('secondPlacePrize', editContest.secondPlacePrize);
      formData.append('thirdPlacePrize', editContest.thirdPlacePrize);
      formData.append('visibility', editContest.visibility);
      if (image) {
        formData.append('image', image);
      }

      // Get the league from localStorage
      const isNFL = localStorage.getItem('isNFL') === 'true';
      const leagueParam = isNFL ? 'nfl' : 'cl';
      formData.append('league', leagueParam);

      const token = localStorage.getItem('authToken');

      await axios.put(
        `/api/admin/contests/${editContest.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        }
      );

      setEditContest(null);
      setImage(null); // Clear the image state

      fetchContests(); // Reload contests after update
    } catch (error) {
      // console.error('Error updating contest:', error.message);
    }
  };

  const handleDeleteContest = async (contestId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this contest?");
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem('authToken');

      await axios.delete(`/api/admin/contests/${contestId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      fetchContests(); // Reload contests after deletion
    } catch (error) {
      // console.error('Error deleting contest:', error.message);
    }
  };

  const renderRank = (index) => {
    switch (index) {
      case 0:
        return '🥇';
      case 1:
        return '🥈';
      case 2:
        return '🥉';
      default:
        return index + 1;
    }
  };

  const getPreviousRank = (userId, data) => {
    for (let i = data.length - 1; i >= 0; i--) {
      const entry = data[i];
      if (entry.id === userId) {
        return entry.rank;
      }
    }
    return null;
  };

  const isMobile = window.innerWidth <= 640;

  const getChartOptions = (theme) => {
    const themeStyles = {
      light: {
        textColor: '#000',
        gridColor: '#e5e7eb',
        tooltipBgColor: '#ffffff',
        legendTextColor: '#000',
      },
      dark: {
        textColor: '#fff',
        gridColor: '#374151',
        tooltipBgColor: '#121212',
        legendTextColor: '#fff',
      },
      sunset: {
        textColor: '#64ffda',
        gridColor: '#4b5563',
        tooltipBgColor: '#1f2937',
        legendTextColor: '#64ffda',
      },
    };

    const styles = themeStyles[theme] || themeStyles.light;

    const themeSpecificOptions = {
      scales: {
        y: {
          ticks: {
            color: styles.textColor,
            stepSize: 1,
            precision: 0,
          },
          grid: {
            color: styles.gridColor,
          },
          beginAtZero: true, // Ensures y-axis starts at 0
        },
        x: {
          type: 'time',
          time: {
            unit: 'day',
            tooltipFormat: 'MM/dd/yyyy',
            displayFormats: {
              day: 'MM/dd/yyyy',
            },
          },
          ticks: {
            color: styles.textColor,
            autoSkip: true,
            maxTicksLimit: isMobile ? 3 : 10, // Max 3 dates on mobile
          },
          grid: {
            color: styles.gridColor,
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: styles.legendTextColor,
            generateLabels: (chart) => {
              return chart.data.datasets.map((dataset, i) => ({
                text: `${dataset.label}`,
                fillStyle: dataset.borderColor,
                hidden: !chart.isDatasetVisible(i),
                datasetIndex: i,
                pointStyle: 'circle',
                lineCap: 'round',
                lineWidth: 1,
                borderColor: dataset.borderColor,
                usePointStyle: true,
                pointRadius: 2,
                fontColor: styles.legendTextColor,
              }));
            },
          },
        },
        tooltip: {
          backgroundColor: styles.tooltipBgColor,
          titleColor: styles.textColor,
          bodyColor: styles.textColor,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          },
          zoom: {
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
          limits: {
            x: { min: 'original', max: 'original' },
            y: { min: 'original', max: 'original' },
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      onClick: (e, elements, chart) => {
        if (e.native && e.native.detail === 2) {
          chart.resetZoom();
        }
      },
      layout: {
        padding: {
          left: 0,
        },
      },
    };

    return merge({}, staticChartOptions, themeSpecificOptions);
  };

  const staticChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MM/dd/yyyy',
          displayFormats: {
            day: 'MM/dd/yyyy',
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          generateLabels: (chart) => {
            return chart.data.datasets.map((dataset, i) => ({
              text: `${dataset.label}`,
              fillStyle: dataset.borderColor,
              hidden: !chart.isDatasetVisible(i),
              datasetIndex: i,
              pointStyle: 'circle',
              lineCap: 'round',
              lineWidth: 1,
              borderColor: dataset.borderColor,
              usePointStyle: true,
              pointRadius: 2,
            }));
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div className={`min-h-screen ${styles.bg} p-4 sm:p-8`}>
      {loading && <LoadingSpinner theme={theme} />} {/* Show loading spinner when loading is true */}
      <h2 className={`text-2xl sm:text-3xl font-bold mb-4 sm:mb-8 text-center ${styles.highlight}`}>
        {league === 'nfl' ? 'NFL Leaderboard' : 'Champions League Leaderboard'}
      </h2>

      {/* Timeframe and Contest Selector */}
      <div className="text-center mb-4 flex flex-col sm:flex-row justify-center space-y-2 sm:space-y-0 sm:space-x-4">
        <select
          value={selectedOption}
          onChange={handleSelectionChange}
          className={`p-2 rounded ${styles.cardBg} ${styles.text}`}
        >
          <option value="all_time">All Time</option>
          <option value="last_week">Last Week</option>
          <option value="last_month">Last Month</option>
          <option value="custom">Custom</option>
          {activeContests.map((contest) => (
            <option key={contest.id} value={contest.id}>
              {contest.name}
            </option>
          ))}
        </select>

        {selectedOption === 'custom' && (
          <>
            <label className={`${styles.text} mr-2`}>Start Date:</label>
            <input
              type="date"
              value={historyDateRange.start}
              min="2024-08-17"
              onChange={(e) => handleHistoryDateRangeChange('start', e.target.value)}
              className={`p-2 rounded ${styles.cardBg} ${styles.text}`}
            />
            <label className={`${styles.text} ml-4 mr-2`}>End Date:</label>
            <input
              type="date"
              value={historyDateRange.end}
              min="2024-08-17"
              onChange={(e) => handleHistoryDateRangeChange('end', e.target.value)}
              className={`p-2 rounded ${styles.cardBg} ${styles.text}`}
            />
          </>
        )}
      </div>

      {/* Conditional Rendering */}
      {trendData && trendData.datasets.length > 0 ? (
        <>
          {/* Trend Graph */}
          <div className="text-center mb-4 sm:mb-8 mt-4 sm:mt-6">
            <div className="mx-auto w-full sm:max-w-2xl" style={{ width: '100%' }}>
              <Line data={trendData} options={getChartOptions(theme)} height={300} />
            </div>
          </div>

          {/* Leaderboard Table */}
          <ul className={`max-w-lg sm:max-w-2xl mx-auto ${styles.cardBg} shadow-lg rounded-lg`}>
            {leaderboard && leaderboard.length > 0 ? (
              leaderboard.map((entry, index) => {
                const previousRank = getPreviousRank(entry.id, leaderboard);
                const rankChange = calculateRankChange(index + 1, previousRank);

                return (
                  <li
                    key={`${entry.id}-${index}`}
                    className={`p-3 sm:p-4 border-b ${styles.border} flex items-center ${styles.hover} ${
                      entry.id === user?.id ? styles.highlightUser : ''
                    }`}
                  >
                    <span className={`text-sm sm:text-lg font-bold mr-2 sm:mr-4 ${styles.highlight}`}>
                      {renderRank(index)}
                    </span>
                    <img
                      src={entry.profile_picture || '/default-profile.png'}
                      alt={entry.name}
                      className="w-8 h-8 sm:w-10 sm:h-10 rounded-full cursor-pointer mr-2 sm:mr-4"
                      onClick={() => navigate(`/profile/${entry.id}`)}
                    />
                    <span
                      className={`font-semibold cursor-pointer ${styles.text} text-xs sm:text-base`}
                      onClick={() => navigate(`/profile/${entry.id}`)}
                    >
                      {entry.name}
                    </span>
                    <span className={`ml-auto ${styles.userText} text-xs sm:text-base`}>
                      {entry.points}
                      {isMobile ? 'P' : ' points'}
                    </span>
                    <span className={`ml-2 sm:ml-4 ${styles.userText} text-xs sm:text-base`}>
                      {rankChange.symbol} {rankChange.change}
                    </span>
                    <button
                      onClick={() => handleUserSelection(entry.id)}
                      className="ml-2 sm:ml-4 text-lg sm:text-2xl cursor-pointer"
                    >
                      {selectedUsers.includes(entry.id) ? '❌' : '➕'}
                    </button>
                  </li>
                );
              })
            ) : (
              <li className={`p-4 text-center ${styles.text}`}>No leaderboard data available.</li>
            )}
          </ul>
        </>
      ) : (
        <div className="text-center mb-4 sm:mb-8 mt-4 sm:mt-6">
          <p className={`text-xl ${styles.text}`}>
            {selectedOption !== 'contest' ? "The contest hasn't started yet." : "No data available for this contest."}
          </p>
        </div>
      )}

      {/* Separator */}
      <hr className="my-8 sm:my-12 border-t-2 border-gray-300" />

      {/* Active Contests */}
      <div className="contests mt-4 sm:mt-8 max-w-lg sm:max-w-2xl mx-auto">
        <h2 className={`text-2xl font-bold mb-4 ${styles.highlight}`}>🎉 Active Contests</h2>
        {activeContests.length > 0 ? (
          activeContests.map((contest) => {
            return (
              <div
                key={contest.id}
                className={`relative flex flex-col sm:flex-row p-4 border ${styles.border} rounded-lg mb-4 transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg`}
              >
                {/* Image Section */}
                <div
                  className="sm:w-1/3 h-48 sm:h-auto rounded-lg overflow-hidden"
                  style={{
                    backgroundImage: `url(${contest.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>

                {/* Content Section */}
                <div className="sm:w-2/3 p-4 flex flex-col justify-between">
                  <div>
                    <h3 className="text-2xl font-semibold">{contest.name}</h3>
                    <p className="mt-2">
                      📅 Date Range: {format(new Date(contest.start_date), 'PP')} -{' '}
                      {format(new Date(contest.end_date), 'PP')}
                    </p>
                    <p className="mt-2">🎁 Prizes:</p>
                    <ul className="ml-4">
                      <li>🥇 1st Place: {contest.first_place_prize}</li>
                      <li>🥈 2nd Place: {contest.second_place_prize}</li>
                      <li>🥉 3rd Place: {contest.third_place_prize}</li>
                    </ul>
                  </div>

                  {/* Admin Controls */}
                  {isAuthenticated && user?.id === 1 && (
                    <div className="mt-4 flex justify-between">
                      <button
                        onClick={() => handleEditContest(contest)}
                        className={`p-2 rounded ${styles.cardBg} ${styles.text}`}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteContest(contest.id)}
                        className={`p-2 rounded ${styles.cardBg} ${styles.text}`}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p className={styles.text}>No active contests available at this time.</p>
        )}
      </div>

      {/* Upcoming Contests */}
      <div className="contests mt-4 sm:mt-8 max-w-lg sm:max-w-2xl mx-auto">
        <h2 className={`text-2xl font-bold mb-4 ${styles.highlight}`}>⏳ Upcoming Contests</h2>
        {upcomingContests.length > 0 ? (
          upcomingContests.map((contest) => {
            return (
              <div
                key={contest.id}
                className={`relative flex flex-col sm:flex-row p-4 border ${styles.border} rounded-lg mb-4 transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg`}
              >
                {/* Image Section */}
                <div
                  className="sm:w-1/3 h-48 sm:h-auto rounded-lg overflow-hidden"
                  style={{
                    backgroundImage: `url(${contest.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>

                {/* Content Section */}
                <div className="sm:w-2/3 p-4 flex flex-col justify-between">
                  <div>
                    <h3 className="text-2xl font-semibold">{contest.name}</h3>
                    <p className="mt-2">📅 Start Date: {format(new Date(contest.start_date), 'PP')}</p>
                    <p className="mt-2">🎁 Prizes:</p>
                    <ul className="ml-4">
                      <li>🥇 1st Place: {contest.first_place_prize}</li>
                      <li>🥈 2nd Place: {contest.second_place_prize}</li>
                      <li>🥉 3rd Place: {contest.third_place_prize}</li>
                    </ul>
                  </div>

                  {/* Admin Controls */}
                  {isAuthenticated && user?.id === 1 && (
                    <div className="mt-4 flex justify-between">
                      <button
                        onClick={() => handleEditContest(contest)}
                        className={`p-2 rounded ${styles.cardBg} ${styles.text}`}
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteContest(contest.id)}
                        className={`p-2 rounded ${styles.cardBg} ${styles.text}`}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <p className={styles.text}>No upcoming contests available at this time.</p>
        )}
      </div>

      {/* Admin Panel */}
      {isAuthenticated && user?.id === 1 && (
        <div className="admin-panel mt-8 sm:mt-12">
          <h2 className={`text-2xl font-bold mb-4 ${styles.highlight}`}>
            {editContest ? 'Edit Contest' : 'Create New Contest'}
          </h2>
          <div className={`p-4 border ${styles.border} rounded-lg`}>
            <h3 className={`text-xl font-semibold ${styles.text}`}>
              {editContest ? 'Update Contest Details' : 'Enter Contest Details'}
            </h3>
            <div className="mb-4">
              <label className={styles.text}>Contest Name:</label>
              <input
                type="text"
                name="name"
                value={editContest ? editContest.name || '' : newContest.name || ''}
                onChange={handleInputChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              />
            </div>
            <div className="mb-4">
              <label className={styles.text}>Start Date:</label>
              <input
                type="date"
                name="startDate"
                value={editContest ? editContest.startDate || '' : newContest.startDate || ''}
                onChange={handleInputChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              />
            </div>
            <div className="mb-4">
              <label className={styles.text}>End Date:</label>
              <input
                type="date"
                name="endDate"
                value={editContest ? editContest.endDate || '' : newContest.endDate || ''}
                onChange={handleInputChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              />
            </div>
            <div className="mb-4">
              <label className={styles.text}>First Place Prize:</label>
              <input
                type="text"
                name="firstPlacePrize"
                value={editContest ? editContest.firstPlacePrize || '' : newContest.firstPlacePrize || ''}
                onChange={handleInputChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              />
            </div>
            <div className="mb-4">
              <label className={styles.text}>Second Place Prize:</label>
              <input
                type="text"
                name="secondPlacePrize"
                value={editContest ? editContest.secondPlacePrize || '' : newContest.secondPlacePrize || ''}
                onChange={handleInputChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              />
            </div>
            <div className="mb-4">
              <label className={styles.text}>Third Place Prize:</label>
              <input
                type="text"
                name="thirdPlacePrize"
                value={editContest ? editContest.thirdPlacePrize || '' : newContest.thirdPlacePrize || ''}
                onChange={handleInputChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              />
            </div>
            <div className="mb-4">
              <label className={styles.text}>Visibility:</label>
              <select
                name="visibility"
                value={editContest ? editContest.visibility || '' : newContest.visibility || ''}
                onChange={handleInputChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
              </select>
            </div>
            <div className="mb-4">
              <label className={styles.text}>Upload Image:</label>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                className={`p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              />
            </div>
            <button
              onClick={editContest ? handleUpdateContest : handleCreateContest}
              className={`mt-4 p-2 rounded ${styles.cardBg} ${styles.highlight} w-full`}
            >
              {editContest ? 'Update Contest' : 'Create Contest'}
            </button>
            {editContest && (
              <button
                onClick={() => setEditContest(null)}
                className={`mt-4 p-2 rounded ${styles.cardBg} ${styles.text} w-full`}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaderboardPage;
