// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        // console.log('Decoded token:', decodedToken);

        if (decodedToken.exp * 1000 < Date.now()) {
          // console.log('Token expired');
          setIsAuthenticated(false);
          setUser(null);
          localStorage.removeItem('authToken');
        } else {
          fetchProfile(token);
        }
      } catch (error) {
        // console.error('Error decoding token:', error);
        setIsAuthenticated(false);
        setUser(null);
      }
    } else {
      fetchProfile();
    }
  }, []);

  const fetchProfile = async (token = null) => {
    try {
      const config = {
        headers: {},
        withCredentials: true,
      };
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      // console.log('Fetching profile with config:', config);
      const response = await axios.get('/api/auth/profile', config);
      setUser(response.data);
      setIsAuthenticated(true);
      // console.log('User profile fetched:', response.data);
    } catch (error) {
      setUser(null);
      setIsAuthenticated(false);
      // console.error('Error fetching profile:', error.response ? error.response.data : error.message);
    }
  };

  const loginWithEmail = async (email, password) => {
    try {
      // console.log('Login attempt:', { email, password }); // Add this line to log the credentials being sent
      const response = await axios.post(
        '/api/auth/login',
        { email, password },
        { withCredentials: true }
      );
      // console.log('Login response:', response.data);
      localStorage.setItem('authToken', response.data.token);
      fetchProfile(response.data.token);
      navigate('/settings');
    } catch (error) {
      // console.error('Email login failed:', error.response ? error.response.data : error.message);
    }
  };
  

  const logout = async () => {
    try {
      await axios.post('/api/auth/logout', {}, { withCredentials: true });
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      setUser(null);
      navigate('/');
    } catch (error) {
      // console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loginWithEmail, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
