import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from '../contexts/AuthContext';
import MatchDetail from '../components/MatchDetail';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const ResultsPage = ({ league, theme }) => {
  const [results, setResults] = useState([]);
  const [userPredictions, setUserPredictions] = useState({});
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [hoveredMatch, setHoveredMatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterPredictions, setFilterPredictions] = useState('all');
  const [dateOrder, setDateOrder] = useState('desc');
  const [filterDate, setFilterDate] = useState(null);
  const [showPredictionErrorModal, setShowPredictionErrorModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [matchPredictions, setMatchPredictions] = useState([]);
  const [predictionLoading, setPredictionLoading] = useState(false);
  const [expandedPredictions, setExpandedPredictions] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [longPressTimer, setLongPressTimer] = useState(null);

  const { isAuthenticated, user } = useContext(AuthContext);
  const latestHoveredMatchRef = useRef(null);

  const handleMouseEnter = (match) => {
    if (hoveredMatch !== match.id) {
      setHoveredMatch(match.id);
      latestHoveredMatchRef.current = match.id;
      fetchMatchPredictions(match.id);
    }
  };

  const handleMouseLeave = (match) => {
    if (hoveredMatch === match.id) {
      setHoveredMatch(null);
      setMatchPredictions([]);
    }
  };

  const handleTouchStart = useCallback((match) => {
    const timer = setTimeout(() => handleMouseEnter(match), 500);
    setLongPressTimer(timer);
  }, []);

  const handleTouchEnd = () => {
    clearTimeout(longPressTimer);
    setLongPressTimer(null);
  };

  const logApiResponse = (endpoint, data) => {
    // console.log(`API Response from ${endpoint}:`, JSON.stringify(data, null, 2));
  };

  const fetchMatchPredictions = async (matchId) => {
    setPredictionLoading(true);
    setMatchPredictions([]);
    try {
      const response = await axios.get(`/api/predictions/match/${matchId}/predictions`);
      if (latestHoveredMatchRef.current === matchId) {
        setMatchPredictions(response.data);
      }
      logApiResponse(`/api/predictions/match/${matchId}/predictions`, response.data);
    } catch (error) {
      // console.error('Error fetching match predictions:', error);
    } finally {
      setPredictionLoading(false);
    }
  };

  useEffect(() => {
    const detectMobile = () => setIsMobile(window.innerWidth <= 768);
    detectMobile();
    window.addEventListener('resize', detectMobile);

    return () => window.removeEventListener('resize', detectMobile);
  }, []);

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/${league}/results/`);
        setResults(response.data);
        logApiResponse(`/api/${league}/results/`, response.data);
      } catch (error) {
        // console.error('Error fetching results:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUserPredictions = async () => {
      if (isAuthenticated && user && user.id) {
        try {
          const response = await axios.get(`/api/predictions/${user.id}`);
          const latestPredictions = response.data.reduce((acc, prediction) => {
            acc[prediction.match_id] = prediction;
            return acc;
          }, {});
          setUserPredictions(latestPredictions);
        } catch (error) {
          // console.error('Error fetching user predictions:', error);
        }
      }
    };

    fetchResults();
    fetchUserPredictions();
  }, [league, isAuthenticated, user]);

  useEffect(() => {
    if (results.length > 0) {
      results.forEach(result => {
        if (result.strStatus === 'FT') {
          toast.info(`Final Score: ${result.strHomeTeam} ${result.intHomeScore}:${result.intAwayScore} ${result.strAwayTeam}`);
        }
      });
    }
  }, [results]);

  const handleFilterChange = (filterType, value) => {
    switch (filterType) {
      case 'status':
        setFilterStatus(value);
        break;
      case 'predictions':
        setFilterPredictions(value);
        break;
      case 'dateOrder':
        setDateOrder(value);
        break;
      default:
        break;
    }
  };

  const handleMatchClick = (match) => {
    setSelectedMatch(match);
  };

  const handleCloseModal = () => {
    setSelectedMatch(null);
    setShowAuthModal(false);
    setShowPredictionErrorModal(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  const handleExpandPredictions = (e) => {
    e.stopPropagation();
    setExpandedPredictions(!expandedPredictions);
  };

  const getMatchStatus = (status) => {
    const finishedStatuses = ['FT', 'Full Time', 'finished', 'Match Finished'];
    if (finishedStatuses.includes(status)) return 'finished';
    return 'upcoming';
  };

  const getImageSource = (venueThumb, thumb) => {
    return venueThumb && venueThumb.trim()
      ? venueThumb
      : thumb && thumb.trim()
        ? thumb
        : null;
  };

  const getVenueText = (venue, location, country) => {
    if (!venue) return '';

    let locationText = location || '';

    if (locationText.includes(country)) {
      country = '';
    }

    locationText = `${locationText}${country ? `, ${country}` : ''}`;

    return `${venue}${locationText ? `, ${locationText}` : ''}`;
  };

  const getPredictionOutcome = (result, prediction) => {
    if (!result || !prediction) return null;
    const outcome = result.intHomeScore > result.intAwayScore ? 'home' :
                    result.intHomeScore < result.intAwayScore ? 'away' : 'draw';
    return outcome === prediction ? 'correct' : 'incorrect';
  };

  const applyFilters = (matches) => {
    return matches
      .filter((match) => {
        const matchStatus = getMatchStatus(match.strStatus);
        if (filterStatus !== 'all' && matchStatus !== filterStatus) return false;
        if (filterPredictions === 'myPredictions' && !userPredictions[match.id]) return false;
        if (filterPredictions === 'notPredicted' && userPredictions[match.id]) return false;
        if (filterDate && new Date(match.dateEvent).toDateString() !== filterDate.toDateString()) return false;
        return true;
      })
      .sort((a, b) => {
        const dateA = new Date(`${a.dateEvent}T${a.strTime}Z`);
        const dateB = new Date(`${b.dateEvent}T${b.strTime}Z`);
        return dateOrder === 'desc' ? dateB - dateA : dateA - dateB;
      });
  };

  if (loading) {
    return (
      <div className={`fixed inset-0 flex items-center justify-center ${theme === 'dark' ? 'bg-black bg-opacity-75' : theme === 'sunset' ? 'bg-gray-900 bg-opacity-75' : 'bg-gray-100 bg-opacity-75'}`}>
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  const filteredResults = applyFilters(results);

  return (
    <div className={`min-h-screen p-4 sm:p-6 ${theme === 'dark' ? 'bg-black' : theme === 'sunset' ? 'bg-gray-900' : 'bg-gray-50'}`}>
      <div className={`max-w-7xl mx-auto shadow-md rounded-md p-4 sm:p-6 ${theme === 'dark' ? 'bg-black text-white' : theme === 'sunset' ? 'bg-gray-800 text-[#64ffda]' : 'bg-white'}`}>
        <h1 className={`text-3xl sm:text-4xl font-extrabold text-center mb-6 ${theme === 'dark' ? 'text-white' : theme === 'sunset' ? 'text-[#64ffda]' : 'text-[#064e3b]'}`}>
          {league === 'nfl' ? 'NFL' : 'Champions League'} Results
        </h1>

        <Disclosure>
          {({ open }) => (
            <div className={`rounded-lg ${open ? 'shadow-lg' : ''} ${theme === 'dark' ? 'shadow-white/50' : theme === 'sunset' ? 'shadow-[#64ffda]/50' : 'shadow-gray-500/50'}`}>
              <Disclosure.Button
                className={`flex justify-between w-full p-4 text-sm font-medium ${
                  theme === 'dark'
                    ? `bg-black text-white ${open ? 'rounded-t-lg' : 'rounded-lg'}`
                    : theme === 'sunset'
                    ? `bg-gray-800 text-[#64ffda] ${open ? 'rounded-t-lg' : 'rounded-lg'}`
                    : 'bg-white text-black shadow-lg shadow-gray-500/50 rounded-lg'
                }`}
                style={{
                  borderBottomLeftRadius: open ? '0' : '0.5rem',
                  borderBottomRightRadius: open ? '0' : '0.5rem',
                }}
              >
                <span>Filters</span>
                <ChevronDownIcon
                  className={`${open ? 'transform rotate-180' : ''} w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel
                className={`p-4 rounded-b-lg ${
                  theme === 'dark' ? 'bg-black text-white' : theme === 'sunset' ? 'bg-gray-800 text-[#64ffda]' : 'bg-white text-black'
                }`}
              >
                <div className="grid grid-cols-2 gap-4 sm:flex sm:flex-row sm:space-x-4 w-full sm:w-auto">
                  <select
                    value={filterStatus}
                    onChange={(e) => handleFilterChange('status', e.target.value)}
                    className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${theme === 'dark' ? 'border-gray-700 bg-black text-white' : theme === 'sunset' ? 'border-gray-700 bg-gray-800 text-white' : 'border-gray-200 bg-white'}`}
                  >
                    <option value="all">All</option>
                    <option value="finished">Finished Matches</option>
                  </select>
                  {isAuthenticated && (
                    <select
                      value={filterPredictions}
                      onChange={(e) => handleFilterChange('predictions', e.target.value)}
                      className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${theme === 'dark' ? 'border-gray-700 bg-black text-white' : theme === 'sunset' ? 'border-gray-700 bg-gray-800 text-white' : 'border-gray-200 bg-white'}`}
                    >
                      <option value="all">All</option>
                      <option value="myPredictions">My Predictions</option>
                      <option value="notPredicted">Not Predicted</option>
                    </select>
                  )}
                  <select
                    value={dateOrder}
                    onChange={(e) => handleFilterChange('dateOrder', e.target.value)}
                    className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${theme === 'dark' ? 'border-gray-700 bg-black text-white' : theme === 'sunset' ? 'border-gray-700 bg-gray-800 text-white' : 'border-gray-200 bg-white'}`}
                  >
                    <option value="desc">Newest First</option>
                    <option value="asc">Oldest First</option>
                  </select>
                  <DatePicker
                    selected={filterDate}
                    onChange={(date) => setFilterDate(date)}
                    isClearable
                    placeholderText="Select a date"
                    className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${theme === 'dark' ? 'border-gray-700 bg-black text-white' : theme === 'sunset' ? 'border-gray-700 bg-gray-800 text-white' : 'border-gray-200 bg-white'}`}
                  />
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>

        <br></br>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredResults.map((result) => {
            const matchDateTime = new Date(`${result.dateEvent}T${result.strTime}Z`);
            const formattedDate = matchDateTime.toLocaleString('en-US', {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            });
            const matchStatus = getMatchStatus(result.strStatus);
            const userPrediction = userPredictions[result.id];
            let cardBorderClass = `${theme === 'dark' ? 'border-gray-700' : theme === 'sunset' ? 'border-gray-700' : 'border-gray-200'}`;
            let cardGlowClass = '';

            if (userPrediction && matchStatus === 'finished') {
              const outcome = getPredictionOutcome(result, userPrediction.prediction);
              if (outcome === 'correct') {
                cardBorderClass = 'border-green-500';
                cardGlowClass = 'shadow-green-500/50 shadow-lg';
              } else if (outcome === 'incorrect') {
                cardBorderClass = 'border-red-500';
                cardGlowClass = 'shadow-red-500/50 shadow-lg';
              }
            }

            const imageSource = getImageSource(result.strVenueThumb, result.strThumb);
            const venueText = getVenueText(result.strVenue, result.strVenueLocation, result.strVenueCountry);

            return (
              <div
                key={result.id}
                className="relative"
                onMouseLeave={() => handleMouseLeave(result)}
                onTouchStart={() => handleTouchStart(result)}
                onTouchEnd={handleTouchEnd}
              >
                <div
                  className={`relative border-2 rounded-lg transform transition duration-200 p-4 ${cardBorderClass} ${cardGlowClass} ${theme === 'dark' ? 'bg-black text-white' : theme === 'sunset' ? 'bg-gray-800 text-white' : 'bg-white'} hover:cursor-pointer hover:scale-105 hover:shadow-2xl`}
                  onMouseEnter={() => handleMouseEnter(result)}
                  onClick={() => handleMatchClick(result)}
                  style={{ overflow: 'hidden' }}
                >
                  <div className="grid grid-cols-1 gap-y-2">
  <div className={`text-center text-sm font-semibold ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-500'}`}>
    {formattedDate}
  </div>
  <div className="grid grid-cols-3 items-center">
    <div className="flex flex-col items-center">
      <img
        src={result.strHomeTeamBadge}
        alt={result.strHomeTeam}
        className={`w-12 h-12 cursor-pointer ${userPrediction && userPrediction.prediction === 'home' ? `${theme === 'dark' ? 'border-2 border-white rounded-full shadow-md shadow-white/50' : theme === 'sunset' ? 'border-2 border-[#64ffda] rounded-full shadow-md shadow-[#64ffda]/50' : 'border-2 border-[#064e3b] rounded-full shadow-md shadow-[#064e3b]/50'}` : ''}`}
        onClick={() => handleMatchClick(result)}
      />
      <p className={`text-xs mt-2 ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}>{result.strHomeTeam}</p>
    </div>
    <div className="flex justify-center">
      <p
        className={`text-sm font-semibold cursor-pointer ${userPrediction && userPrediction.prediction === 'draw' ? `${theme === 'dark' ? 'border-2 border-white rounded-full shadow-md shadow-white/50 p-2' : theme === 'sunset' ? 'border-2 border-[#64ffda] rounded-full shadow-md shadow-[#64ffda]/50 p-2' : 'border-2 border-[#064e3b] rounded-full shadow-md shadow-[#064e3b]/50 p-2'}` : ''} ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}
        onClick={() => handleMatchClick(result)}
      >
        vs
      </p>
    </div>
    <div className="flex flex-col items-center">
      <img
        src={result.strAwayTeamBadge}
        alt={result.strAwayTeam}
        className={`w-12 h-12 cursor-pointer ${userPrediction && userPrediction.prediction === 'away' ? `${theme === 'dark' ? 'border-2 border-white rounded-full shadow-md shadow-white/50' : theme === 'sunset' ? 'border-2 border-[#64ffda] rounded-full shadow-md shadow-[#64ffda]/50' : 'border-2 border-[#064e3b] rounded-full shadow-md shadow-[#064e3b]/50'}` : ''}`}
        onClick={() => handleMatchClick(result)}
      />
      <p className={`text-xs mt-2 ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}>{result.strAwayTeam}</p>
    </div>
  </div>

                    <div className="text-center">
                      {matchStatus === 'finished' && (
                        <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}>
                          <strong>Score:</strong> {result.intHomeScore} - {result.intAwayScore}
                        </p>
                      )}
                    </div>
                    {imageSource ? (
                      <div className="relative w-full h-32 mt-2 overflow-hidden rounded-lg">
                        <img
                          src={imageSource}
                          alt={result.strVenue}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute bottom-0 w-full bg-black bg-opacity-50 text-white text-xs text-center p-1">
                          {venueText}
                        </div>
                      </div>
                    ) : (
                      <div className={`relative w-full h-32 flex justify-center items-center rounded-lg ${theme === 'dark' ? 'bg-gray-700' : theme === 'sunset' ? 'bg-gray-700' : 'bg-gray-200'}`}>
                        <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-500'}`}>No image available</p>
                      </div>
                    )}
                  </div>

                  {/* Sliding Prediction Box */}
                  <div
                    className={`absolute inset-x-0 bottom-0 transform transition-transform duration-300 ease-in-out ${
                      hoveredMatch === result.id ? 'translate-y-0' : 'translate-y-full'
                    } ${theme === 'dark' ? 'bg-gray-900' : theme === 'sunset' ? 'bg-gray-800' : 'bg-white'} p-4 border-t-2`}
                    onTouchStart={(e) => e.stopPropagation()}
                  >
                    {predictionLoading ? (
                      <div className="flex justify-center items-center">
                        <div className="w-8 h-8 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
                      </div>
                    ) : matchPredictions.length === 0 ? (
                      <p className={`text-center text-xs ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}>No predictions yet</p>
                    ) : (
                      <div>
                        <div
                          className="flex justify-between items-center cursor-pointer"
                          onClick={handleExpandPredictions}
                        >
                          <div className="w-1/3 flex flex-wrap items-center justify-center">
                            {matchPredictions
                              .filter((p) => p.prediction === 'home')
                              .slice(0, 10)
                              .map((prediction, index) => (
                                <div
                                  key={index}
                                  className={`relative block hover:z-10 w-8 h-8 transition-transform transform ${expandedPredictions ? 'scale-110' : 'scale-100'}`}
                                  style={{
                                    marginLeft: index !== 0 ? (expandedPredictions ? '0.25rem' : '-0.5rem') : '0',
                                    marginTop: index !== 0 ? (expandedPredictions ? '0.25rem' : '-0.5rem') : '0',
                                  }}
                                  onClick={() => handleMatchClick(result)}
                                >
                                  <img
                                    src={prediction.profile_picture}
                                    alt={prediction.name}
                                    className={`rounded-full object-cover w-full h-full transition-all ${expandedPredictions ? 'hover:w-12 hover:h-12' : 'hover:w-10 hover:h-10'}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  />
                                </div>
                              ))}
                            {matchPredictions.filter((p) => p.prediction === 'home').length > 10 && (
                              <span className={`text-xs ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}>
                                +{matchPredictions.filter((p) => p.prediction === 'home').length - 10}
                              </span>
                            )}
                          </div>
                          <div className="w-1/3 flex flex-wrap items-center justify-center">
                            {matchPredictions
                              .filter((p) => p.prediction === 'draw')
                              .slice(0, 10)
                              .map((prediction, index) => (
                                <div
                                  key={index}
                                  className={`relative block hover:z-10 w-8 h-8 transition-transform transform ${expandedPredictions ? 'scale-110' : 'scale-100'}`}
                                  style={{
                                    marginLeft: index !== 0 ? (expandedPredictions ? '0.25rem' : '-0.5rem') : '0',
                                    marginTop: index !== 0 ? (expandedPredictions ? '0.25rem' : '-0.5rem') : '0',
                                  }}
                                  onClick={() => handleMatchClick(result)}
                                >
                                  <img
                                    src={prediction.profile_picture}
                                    alt={prediction.name}
                                    className={`rounded-full object-cover w-full h-full transition-all ${expandedPredictions ? 'hover:w-12 hover:h-12' : 'hover:w-10 hover:h-10'}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  />
                                </div>
                              ))}
                            {matchPredictions.filter((p) => p.prediction === 'draw').length > 10 && (
                              <span className={`text-xs ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}>
                                +{matchPredictions.filter((p) => p.prediction === 'draw').length - 10}
                              </span>
                            )}
                          </div>
                          <div className="w-1/3 flex flex-wrap items-center justify-center">
                            {matchPredictions
                              .filter((p) => p.prediction === 'away')
                              .slice(0, 10)
                              .map((prediction, index) => (
                                <div
                                  key={index}
                                  className={`relative block hover:z-10 w-8 h-8 transition-transform transform ${expandedPredictions ? 'scale-110' : 'scale-100'}`}
                                  style={{
                                    marginLeft: index !== 0 ? (expandedPredictions ? '0.25rem' : '-0.5rem') : '0',
                                    marginTop: index !== 0 ? (expandedPredictions ? '0.25rem' : '-0.5rem') : '0',
                                  }}
                                  onClick={() => handleMatchClick(result)}
                                >
                                  <img
                                    src={prediction.profile_picture}
                                    alt={prediction.name}
                                    className={`rounded-full object-cover w-full h-full transition-all ${expandedPredictions ? 'hover:w-12 hover:h-12' : 'hover:w-10 hover:h-10'}`}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                  />
                                </div>
                              ))}
                            {matchPredictions.filter((p) => p.prediction === 'away').length > 10 && (
                              <span className={`text-xs ${theme === 'dark' ? 'text-gray-400' : theme === 'sunset' ? 'text-gray-400' : 'text-gray-600'}`}>
                                +{matchPredictions.filter((p) => p.prediction === 'away').length - 10}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {selectedMatch && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleOutsideClick}
        >
          <div className="relative bg-white rounded-lg p-6 w-full max-w-md" onClick={(e) => e.stopPropagation()}>
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <MatchDetail match={selectedMatch} onClose={handleCloseModal} theme={theme} league={league} />
          </div>
        </div>
      )}

      {showAuthModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={handleOutsideClick}>
          <div className={`relative rounded-lg shadow-lg p-6 max-w-sm ${theme === 'dark' ? 'bg-black text-white' : theme === 'sunset' ? 'bg-gray-800 text-[#64ffda]' : 'bg-white text-black'}`} onClick={(e) => e.stopPropagation()}>
            <button
              className={`absolute top-2 right-2 ${theme === 'dark' ? 'text-gray-300 hover:text-gray-100' : theme === 'sunset' ? 'text-[#64ffda] hover:text-[#52d6b5]' : 'text-gray-600 hover:text-gray-900'}`}
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <h2 className={`text-xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : theme === 'sunset' ? 'text-[#64ffda]' : 'text-blue-600'}`}>Login Required</h2>
            <p className={`mb-4 ${theme === 'dark' ? 'text-gray-300' : theme === 'sunset' ? 'text-gray-300' : 'text-gray-700'}`}>
              You need to log in or register with Twitter to view more details.
            </p>
            <button
              onClick={() => {
                setLoading(true);
                window.location.href = '/api/auth/twitter';
              }}
              className={`py-2 px-4 rounded-md ${theme === 'dark' ? 'bg-gray-700 text-gray-300' : theme === 'sunset' ? 'bg-[#64ffda] text-black' : 'bg-white text-black'} shadow-lg`}
            >
              Sign In with Twitter
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultsPage;
